import React, { useState, useEffect, useContext } from "react"

import AttendEventHeader from "../../components/Hero/AttendEventHero"
import AttendEvents from "../../components/AttendEvents"
import Seo from "../../components/seo"
import { useDocument } from "../../hooks/useDocument"
import { useAuthContext } from "../../hooks/useAuthContext"
import UserNotFound from "../../components/General/userNotFound"
import { graphql } from "gatsby"
import { getDoc, doc } from "firebase/firestore"
import { db } from "../../firebase/config"
import MustSignIn from "../../components/MustSignIn"
import { StateContext } from "../../helpers/StateProvider"
import { Share } from "../../components/LisitngDetails/ListingDetails.styles"
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  TelegramShareButton,
  EmailShareButton,
} from "react-share"
import Faceboook from "../../assets/icons/facebook-share.svg"
import Twitter from "../../assets/icons/twitter-share.svg"
import Linkedin from "../../assets/icons/linkedin-share.svg"
import Whatsapp from "../../assets/icons/whatsapp-icon.svg"
import telegram from "../../assets/icons/telegram_logo_circle_icon.svg"
import email from "../../assets/icons/email-envelop.svg"

const AttendEvent = props => {
  const { authIsReady, user } = useAuthContext()
  const currentUrl = window.location.href

  const [eventData, setEventData] = useState(null)
  const [authorData, setAuthorData] = useState(null)
  const [arabic, setArabic] = useState(false)

  var eventId = props.pageContext.eventId
  if (props.id) {
    eventId = props.id
  } else {
    eventId = props.pageContext.eventId
  }

  useDocument("event", eventId).then(document => {
    if (document.data) {
      setEventData(document.data)
    }
    console.log("event", props)
  })

  useEffect(() => {
    if (eventData) {
      getDoc(doc(db, "users", eventData.added_by)).then(data => {
        setAuthorData(data.data())
      })
    }
  }, [eventData])
  const { language, setLanguage } = useContext(StateContext)

  return (
    <>
      {authIsReady && eventData && authorData && (
        <>
          {!user && <MustSignIn />}
          {user && (
            <>
              <Seo title={`Attend Event - ${eventData.name}`} />
              <AttendEventHeader eventId={eventId} arabic={language} />
              <AttendEvents
                data={eventData}
                eventId={eventId}
                arabic={language}
              />
              <Share>
                <div className="text">
                  {language == false ? "Share on" : "مشاركه فى"}
                </div>
                <div className="socials">
                  <FacebookShareButton
                    url={currentUrl}
                    title="Facebook"
                    hashtag="#property"
                  >
                    <img src={Faceboook} alt="facebook icon" />
                  </FacebookShareButton>
                  <TwitterShareButton url={currentUrl} title="Twitter">
                    <img src={Twitter} alt="twitter icon" />
                  </TwitterShareButton>
                  <LinkedinShareButton url={currentUrl} title="Linkedin">
                    <img src={Linkedin} alt="linkedin icon" />
                  </LinkedinShareButton>
                  <WhatsappShareButton url={currentUrl} title="Whatsapp">
                    <img src={Whatsapp} alt="whatsapp icon" />
                  </WhatsappShareButton>
                  <TelegramShareButton url={currentUrl} title="telegram">
                    <img
                      src={telegram}
                      alt="whatsapp icon"
                      style={{ width: "64px" }}
                    />
                  </TelegramShareButton>
                  <EmailShareButton
                    url={currentUrl}
                    subject="share page"
                    body={"this is link shared"}
                  >
                    <img
                      src={email}
                      alt="whatsapp icon"
                      style={{ width: "50px" }}
                    />
                  </EmailShareButton>
                </div>
              </Share>
            </>
          )}
        </>
      )}
      {authIsReady && !authorData && (
        <>
          <>
            <Seo title={`Not found`} />
            <UserNotFound />
          </>
        </>
      )}
    </>
  )
}
export const query = graphql`
  {
    top: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "top" } }
    ) {
      edges {
        node {
          classname
          id

          label
          label_ar
          link
          type
        }
      }
    }
    followus: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "followus" } }
    ) {
      edges {
        node {
          classname
          id

          label
          link
          type
        }
      }
    }
    browse: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "browse" } }
    ) {
      edges {
        node {
          classname
          id

          label
          label_ar
          link
          type
        }
      }
    }
    explore: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "explore" } }
    ) {
      edges {
        node {
          classname
          id

          label
          label_ar
          link
          type
        }
      }
    }
    ourservices: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "ourservices" } }
    ) {
      edges {
        node {
          classname
          id

          label
          label_ar
          link
          type
        }
      }
    }
    topcategories: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "topcategories" } }
    ) {
      edges {
        node {
          classname
          id

          label
          label_ar
          link
          type
        }
      }
    }
    widget7: widget(name: { eq: "looking-for-exposure" }) {
      title_ar
      link_text_ar
      description_ar
      id
      image

      link
      link_text
      name
      title
      type
      description
      details {
        description
        image

        link
        svg
        title
      }
      localImage {
        childImageSharp {
          fixed(width: 1140, height: 300) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
    widget8: widget(name: { eq: "who-we-are" }) {
      title_ar
      link_text_ar
      description_ar
      id
      image

      link
      link_text
      name
      title
      type
      description
      details {
        description
        image

        link
        svg
        title
      }
    }
    allUsers {
      edges {
        node {
          first_name
          id
          arabic_language
        }
      }
    }
  }
`

export default AttendEvent
