import { Link } from "gatsby"
import React from "react"
import { Container } from "./MustSignIn.styles"

const MustSignIn = () => {
  return (
    <Container>
      <h3>Looks like you are not signed in</h3>
      <p>Please Log In in order to access all features</p>
      <Link className="login" to="/login">
        LOG IN
      </Link>
    </Container>
  )
}

export default MustSignIn
