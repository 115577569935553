import styled from "styled-components"

export const Container = styled.div`
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;

  a.login {
    background-color: var(--lightGreen);
    color: white;
    border-radius: 10px;
    padding: 15px 30px;
    text-decoration: none;
  }
`
